export enum AUTH_ROUTES {
  TALENT_LOGIN = '/auth/talent/login',
}

export enum TALENT_ROUTES {
  DASHBOARD = '/talent/jobs',
  AGENDA = '/talent/agenda',
}

export enum HIRER_ROUTES {
  DASHBOARD = '/hirer/home',
  AUTH = '/hirer/auth',
  LOGIN = '/hirer/login',
  LOGOUT = '/hirer/logout',
  REGISTER = '/hirer/register',
  REGISTER_COMPANY = '/hirer/register/company',
  PROFILE = '/hirer/profile',
  NEW_USER = '/hirer/user/new',
  SCHEDULE_LISTING = '/hirer/schedule',
  SCHEDULE_CREATE = '/hirer/schedule/create',
  AGENDA = '/hirer/agenda',
  CLAIM_PROFILE = '/hirer/claim',
  RATE_TALENT_COMPLETE = '/hirer/rate/complete',
}
