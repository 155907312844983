import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import {
  PaymentMethod,
  RequestPaymentInvoice,
  SelfBilledInvoice,
} from '../helper/type';

const { accounting: accountingUrl } = serviceDomain;

export const useAccountingService = () => {
  const axios = useAxios();

  const listSelfBilledInvoicesByPayeeId = (
    talentId: string
  ): Promise<{
    data: SelfBilledInvoice[];
    totalCount: number;
    lastKey?: string;
  }> =>
    axios.get(`${accountingUrl}/payee/list-self-billed-invoices`, {
      requestKey: `accounting/payee/list-self-billed-invoices/${talentId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to fetch invoices.',
      },
      params: { payeeId: talentId },
    });

  const submitAllowances = (
    invoiceId: string,
    payload: {
      paymentMethod: PaymentMethod;
      items: SelfBilledInvoice['items'];
    }
  ): Promise<SelfBilledInvoice> =>
    axios.post(
      `${accountingUrl}/payee/submit-allowances/${invoiceId}`,
      payload,
      {
        requestKey: `accounting/payee/submit-allowances/${invoiceId}`,
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          failure: 'Unable to submit allowance.',
        },
      }
    );

  const listRequestPaymentInvoicesByPayerId = (
    hirerId: string
  ): Promise<{
    data: RequestPaymentInvoice[];
    totalCount: number;
    lastKey?: string;
  }> =>
    axios.get(`${accountingUrl}/payer/list-request-payment-invoices`, {
      requestKey: `accounting/payer/list-request-payment-invoices/${hirerId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to fetch invoices.',
      },
      params: { payerId: hirerId },
    });

  const approveRequestPaymentInvoice = (
    invoiceId: string
  ): Promise<RequestPaymentInvoice> =>
    axios.post(
      `${accountingUrl}/payer/approve-invoice/${invoiceId}`,
      {},
      {
        requestKey: `accounting/payer/approve-invoice/${invoiceId}`,
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          failure: 'Unable to save invoice.',
        },
      }
    );

  const proceedPayment = (
    invoiceId: string,
    payload: {
      success_redirect_url?: string;
      failure_redirect_url?: string;
    }
  ): Promise<RequestPaymentInvoice> =>
    axios.post(`${accountingUrl}/payer/proceed-payment/${invoiceId}`, payload, {
      requestKey: `accounting/payer/proceed-payment/${invoiceId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to get payment link.',
      },
    });

  return {
    listSelfBilledInvoicesByPayeeId,
    submitAllowances,
    listRequestPaymentInvoicesByPayerId,
    approveRequestPaymentInvoice,
    proceedPayment,
  };
};
