import { RouteProps } from 'react-router-dom';
import AdminAnnouncement from '../page/Admin/Announcement';

const adminRoutes: RouteProps[] = [
  {
    path: '/admin/announcement',
    component: AdminAnnouncement,
  },
];

export default adminRoutes;
