import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Project,
  Role,
  Applicant,
  Schedule,
  Activity,
  HirerCompany,
  HirerUser,
  PendingRating,
  Rating,
  RequestPaymentInvoice,
} from '../helper/type';

interface HirerState {
  companyProfile: HirerCompany;
  companyUsers: HirerUser[];
  mappedProjects: Record<string, Project>;
  mappedRoles: Record<string, Record<string, Role>>;
  mappedApplicants: Record<
    string,
    {
      applicants: Applicant[];
      hasLoadOtherApplicants: boolean;
      lastFetchTimestamp: string;
    }
  >;
  mappedSchedules: Record<string, Schedule>;
  mappedActivities: Record<string, Record<string, Activity[]>>;
  invitationInfo: Partial<Activity>;
  pendingRatings: PendingRating[];
  mappedProjectRatings: Record<string, Rating[]>;
  mappedRequestPaymentInvoice: Record<string, RequestPaymentInvoice>;
}

const initialState = {
  companyProfile: {} as HirerCompany,
  companyUsers: [],
  mappedProjects: {},
  mappedRoles: {},
  mappedApplicants: {},
  mappedSchedules: {},
  mappedActivities: {},
  invitationInfo: {},
  pendingRatings: [],
  mappedProjectRatings: {},
  mappedRequestPaymentInvoice: {},
} as HirerState;

type SetRolesPayload = {
  projectId: string;
  roles: Role[];
};

type SetRolePayload = {
  projectId: string;
  role: Role;
};

type RemoveRolePayload = {
  projectId: string;
  roleId: string;
};

type SetApplicantsPayload = {
  roleId: string;
  applicants: Applicant[];
  hasLoadOtherApplicants: boolean;
  lastFetchTimestamp: string;
};

type SetApplicantPayload = {
  roleId: string;
  applicant: Applicant;
};

type SetApplicantStatusPayload = {
  roleId: string;
  talentId: string;
  status: string;
};

type SetSchedulesPayload = {
  scheduleId: string;
  schedule: Schedule;
};

type SetActivitiesPayload = {
  roleId: string;
  talentId: string;
  activities: Activity[];
};

type SetActivitiesMultiplePayload = {
  roleId: string;
  talentActivities: Record<string, Activity[]>;
};

type SetApplicantRecommendPayload = {
  roleId: string;
  talentId: string;
  isRecommended: boolean;
};

const hirerSlice = createSlice({
  name: 'hirer',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.mappedProjects = action.payload.reduce(
        (acc, project) => ({
          ...acc,
          [project.id]: project,
        }),
        {}
      );
    },
    // update specific project inside mapped projects
    setProject: (state, action: PayloadAction<Project>) => {
      state.mappedProjects[action.payload.id] = action.payload;
    },

    // remove specific project inside mapped projects
    removeProject: (state, action: PayloadAction<string>) => {
      delete state.mappedProjects[action.payload];
    },
    setRoles: (state, action: PayloadAction<SetRolesPayload>) => {
      const { projectId, roles } = action.payload;
      state.mappedRoles[projectId] = roles.reduce(
        (acc, role) => ({
          ...acc,
          [role.id]: role,
        }),
        {}
      );
    },
    setRole: (state, action: PayloadAction<SetRolePayload>) => {
      const { projectId, role } = action.payload;
      state.mappedRoles[projectId] = {
        ...state.mappedRoles[projectId],
        [role.id]: role,
      };
    },

    // remove specific role inside mapped roles
    removeRole: (state, action: PayloadAction<RemoveRolePayload>) => {
      const { projectId, roleId } = action.payload;
      delete state.mappedRoles[projectId][roleId];
    },
    setApplicants: (state, action: PayloadAction<SetApplicantsPayload>) => {
      const { roleId, applicants, hasLoadOtherApplicants, lastFetchTimestamp } =
        action.payload;
      state.mappedApplicants[roleId] = {
        applicants,
        hasLoadOtherApplicants,
        lastFetchTimestamp,
      };
    },
    setApplicant: (state, action: PayloadAction<SetApplicantPayload>) => {
      const { roleId, applicant } = action.payload;

      let applList = state.mappedApplicants[roleId]?.applicants ?? [];
      const found = applList.findIndex(
        (appl) => appl.talentId === applicant.talentId
      );
      if (found === -1) {
        applList = applList.concat([applicant]);
      } else {
        applList[found] = applicant;
      }

      state.mappedApplicants[roleId] = {
        ...state.mappedApplicants[roleId],
        applicants: applList,
      };
    },

    // update specific applicant inside mapped applicants
    setApplicantStatus: (
      state,
      action: PayloadAction<SetApplicantStatusPayload>
    ) => {
      const { roleId, talentId, status } = action.payload;
      const applicantToUpdate = state.mappedApplicants[
        roleId
      ].applicants.filter((applicant) => applicant.talentId === talentId)[0];

      applicantToUpdate.status = status;
      applicantToUpdate.statusUpdatedAt = new Date().toISOString();
    },
    setHirerSchedules: (state, action: PayloadAction<SetSchedulesPayload>) => {
      const { scheduleId, schedule } = action.payload;
      state.mappedSchedules[scheduleId] = schedule;
    },
    setActivities: (state, action: PayloadAction<SetActivitiesPayload>) => {
      const { roleId, talentId, activities } = action.payload;
      state.mappedActivities[roleId] = {
        ...state.mappedActivities[roleId],
        [talentId]: activities,
      };
    },
    setActivitiesMultiple: (
      state,
      action: PayloadAction<SetActivitiesMultiplePayload>
    ) => {
      const { roleId, talentActivities } = action.payload;
      state.mappedActivities[roleId] = {
        ...state.mappedActivities[roleId],
        ...talentActivities,
      };
    },
    setInvitationInfo: (state, action: PayloadAction<Partial<Activity>>) => {
      state.invitationInfo = action.payload;
      localStorage.setItem('_invitationInfo', JSON.stringify(action.payload));
    },
    setApplicantRecommend: (
      state,
      action: PayloadAction<SetApplicantRecommendPayload>
    ) => {
      const { roleId, talentId, isRecommended } = action.payload;
      const applicantToUpdate = state.mappedApplicants[
        roleId
      ].applicants.filter((applicant) => applicant.talentId === talentId)[0];

      applicantToUpdate.isRecommended = isRecommended;
    },
    setCompanyProfile: (state, action: PayloadAction<HirerCompany>) => {
      localStorage.setItem('_companyProfile', JSON.stringify(action.payload));
      state.companyProfile = action.payload;
    },
    clearCompanyProfile: (state) => {
      localStorage.removeItem('_companyProfile');
      state.companyProfile = initialState.companyProfile;
    },
    setCompanyUsers: (state, action: PayloadAction<HirerUser[]>) => {
      state.companyUsers = action.payload;
    },
    setCompanyUser: (state, action: PayloadAction<HirerUser>) => {
      const user = action.payload;
      state.companyUsers = state.companyUsers
        .filter((u) => u.userId !== user.userId)
        .concat([user]);
    },
    setPendingRatings: (state, action: PayloadAction<PendingRating[]>) => {
      state.pendingRatings = action.payload;
    },
    setProjectRatings: (
      state,
      action: PayloadAction<{ projectId: string; ratings: Rating[] }>
    ) => {
      const { projectId, ratings } = action.payload;
      state.mappedProjectRatings[projectId] = ratings;
    },
    setRequestPaymentInvoices: (
      state,
      action: PayloadAction<RequestPaymentInvoice[]>
    ) => {
      action.payload.forEach((invoice) => {
        const { projectId = '' } = invoice;
        state.mappedRequestPaymentInvoice[projectId] = invoice;
      });
    },
  },
});

export const {
  setProjects,
  setProject,
  removeProject,
  setRoles,
  setRole,
  removeRole,
  setApplicants,
  setApplicant,
  setApplicantStatus,
  setHirerSchedules,
  setActivities,
  setActivitiesMultiple,
  setInvitationInfo,
  setApplicantRecommend,
  setCompanyProfile,
  clearCompanyProfile,
  setCompanyUsers,
  setCompanyUser,
  setPendingRatings,
  setProjectRatings,
  setRequestPaymentInvoices,
} = hirerSlice.actions;

export default hirerSlice.reducer;
