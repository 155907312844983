import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import { Settings } from '../helper/type';

const { settings: settingsUrl } = serviceDomain;

type UpdateSettingsPayload = Partial<Settings>;

export const useSettingsService = () => {
  const axios = useAxios();

  const getSettings = (isInterruptive = false) =>
    axios.get<Settings>(`${settingsUrl}/all`, {
      requestKey: 'settings/all',
      isInterruptive,
      withCredentials: true,
      checkTokenValidity: true,
    });

  const updateSettings = (payload: UpdateSettingsPayload) =>
    axios.patch(`${settingsUrl}/update`, payload, {
      requestKey: 'settings/update',
      isInterruptive: true,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: {
        loading: 'Updating your settings',
        success: 'Settings updated',
        failure: true,
      },
    });

  const broadcastTelegram = (imkey: string, message: string) =>
    axios.post(
      `${settingsUrl}/broadcast/telegram`,
      { imkey, message },
      {
        requestKey: 'settings/broadcast/telegram',
        isInterruptive: true,
        withCredentials: false,
        feedback: {
          loading: 'Sending broadcast message',
          success: 'Message sent',
          failure: true,
        },
      }
    );

  const whatsappActivateRequest = (payload: {
    phoneNumberPrefix: string;
    phoneNumber: string;
    loopback?: string;
  }) =>
    axios.post(`${settingsUrl}/whatsapp/activate`, payload, {
      requestKey: 'settings/whatsapp/activate',
      isInterruptive: true,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: {
        failure: 'Unable to activate WhatsApp notification at the moment.',
      },
    });

  const whatsappVerifyRequest = (
    phoneNumberPrefix: string,
    phoneNumber: string,
    verificationCode: string
  ) =>
    axios.post(
      `${settingsUrl}/whatsapp/verify`,
      { phoneNumberPrefix, phoneNumber, verificationCode },
      {
        requestKey: 'settings/whatsapp/verify',
        isInterruptive: true,
        withCredentials: true,
        checkTokenValidity: true,
        feedback: {
          failure: true,
        },
      }
    );

  return {
    getSettings,
    updateSettings,
    broadcastTelegram,
    whatsappActivateRequest,
    whatsappVerifyRequest,
  };
};
