import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Activity,
  JobHistory,
  Rating,
  RatingStats,
  SelfBilledInvoice,
} from '../helper/type';

interface TalentState {
  mappedCurrentApplications: Record<string, JobHistory>;
  mappedPastApplications: Record<string, JobHistory>;
  mappedRatings: Record<string, Record<string, Rating>>;
  mappedRatingStats: Record<string, RatingStats>;
  mappedSelfBilledInvoices: Record<string, Record<string, SelfBilledInvoice>>;
  mappedActivities: Record<string, Activity[]>;
}

const initialState: TalentState = {
  mappedCurrentApplications: {},
  mappedPastApplications: {},
  mappedSelfBilledInvoices: {},
  mappedRatings: {},
  mappedRatingStats: {},
  mappedActivities: {},
};

type SetApplicationsPayload = {
  applications: JobHistory[];
  type: 'current' | 'past';
};

const talentSlice = createSlice({
  name: 'talent',
  initialState,
  reducers: {
    setApplications: (state, action: PayloadAction<SetApplicationsPayload>) => {
      switch (action.payload.type) {
        case 'current':
          state.mappedCurrentApplications = action.payload.applications.reduce(
            (acc, application) => ({
              ...acc,
              [application.roleId]: application,
            }),
            {}
          );
          break;
        case 'past':
          state.mappedPastApplications = action.payload.applications.reduce(
            (acc, application) => ({
              ...acc,
              [application.roleId]: application,
            }),
            {}
          );
          break;
      }
    },
    addApplication: (state, action: PayloadAction<JobHistory>) => {
      state.mappedCurrentApplications[action.payload.roleId] = action.payload;
    },
    setRatings: (
      state,
      action: PayloadAction<{ talentId: string; ratings: Rating[] }>
    ) => {
      const { talentId, ratings } = action.payload;
      const talentRatings: Record<string, Rating> = {};
      ratings.forEach((rating) => (talentRatings[rating.roleId] = rating));
      state.mappedRatings[talentId] = {
        ...state.mappedRatings[talentId],
        ...talentRatings,
      };
    },
    setRatingStats: (state, action: PayloadAction<RatingStats>) => {
      const { talentId } = action.payload;
      state.mappedRatingStats = {
        ...state.mappedRatingStats,
        [talentId]: action.payload,
      };
    },
    setSelfBilledInvoices: (
      state,
      action: PayloadAction<SelfBilledInvoice[]>
    ) => {
      action.payload.forEach((invoice) => {
        const { projectId = '', id = '' } = invoice;
        state.mappedSelfBilledInvoices[projectId] = {
          ...state.mappedSelfBilledInvoices[projectId],
          [id]: invoice,
        };
      });
    },
    setSelfBilledInvoice: (state, action: PayloadAction<SelfBilledInvoice>) => {
      const { projectId = '', id = '' } = action.payload;
      state.mappedSelfBilledInvoices[projectId] = {
        ...state.mappedSelfBilledInvoices[projectId],
        [id]: action.payload,
      };
    },
    setActivities: (
      state,
      action: PayloadAction<{ roleId: string; activities: Activity[] }>
    ) => {
      const { roleId, activities } = action.payload;
      state.mappedActivities = {
        ...state.mappedActivities,
        [roleId]: activities,
      };
    },
  },
});

export const {
  setApplications,
  addApplication,
  setRatings,
  setRatingStats,
  setSelfBilledInvoices,
  setSelfBilledInvoice,
  setActivities,
} = talentSlice.actions;

export default talentSlice.reducer;
