import { nanoid } from 'nanoid';
import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';

const { fileManage: fileManageUrl } = serviceDomain;

export type UploadFilePayload = {
  scope: string;
  directory: string;
  fileName?: string;
  file: File;
};

export const useFileManageService = () => {
  const axios = useAxios();

  const uploadFile = async (
    payload: UploadFilePayload
  ): Promise<{ url: string; fileName?: string }> => {
    const { scope, directory, fileName = nanoid(), file } = payload;
    const ext = file.name.split('.').slice(1).pop() ?? '';

    const srcFileName = file.name;
    const apiFileName = `${fileName}.${ext}`;

    const {
      url: uploadUrl,
      cdnUrl,
      fields,
    } = await axios.get<{
      url: string;
      cdnUrl: string;
      fields: Record<string, string>;
    }>(`${fileManageUrl}/upload/url/post`, {
      requestKey: 'fileManage/upload/url/post/get',
      isInterruptive: true,
      withCredentials: true,
      feedback: { failure: `Unable to get upload url: ${srcFileName}` },
      params: { scope, directory, fileName: apiFileName },
    });

    const formData = new FormData();
    Object.entries(fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', file);

    await axios.post(uploadUrl, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      requestKey: `fileManage/upload/${apiFileName}`,
      isInterruptive: true,
      withCredentials: false,
    });

    return { url: cdnUrl, fileName };
  };

  const deleteFile = async (payload: { keys: string[] }) =>
    axios.post(`${fileManageUrl}/delete`, payload, {
      requestKey: 'fileManage/delete',
      isInterruptive: true,
      withCredentials: true,
    });

  return {
    uploadFile,
    deleteFile,
  };
};
