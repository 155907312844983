import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import { JobHistory, Project, Role } from '../helper/type';

const { job: jobUrl } = serviceDomain;

type ProjectPayload = Partial<Project>;
type RolePayload = Partial<Role>;

export const useJobService = () => {
  const axios = useAxios();

  const getProject = (projectId: string, checkActive?: 'false') =>
    axios.get(`${jobUrl}/project/${projectId}`, {
      requestKey: 'job/project/get',
      isInterruptive: false,
      withCredentials: true,
      feedback: {
        failure: true,
      },
      params: { checkActive },
    });

  const getRole = (roleId: string) =>
    axios.get(`${jobUrl}/role/${roleId}`, {
      requestKey: 'job/role/get',
      isInterruptive: false,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const getAllActiveProjects = (
    key?: string
  ): Promise<{
    data: (Project & { loading: boolean })[];
    key: string;
  }> => {
    return axios.get(`${jobUrl}/projects`, {
      requestKey: 'job/project/list/active',
      isInterruptive: false,
      withCredentials: true,
      feedback: {
        failure: true,
      },
      params: {
        key,
      },
    });
  };

  const getProjectsByHirer = (hirerId: string) =>
    axios.get(`${jobUrl}/projects?k=${hirerId}`, {
      requestKey: 'job/project/list',
      isInterruptive: false,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const getRolesByProject = (projectId: string): Promise<Role[]> =>
    axios.get(`${jobUrl}/project/${projectId}/roles`, {
      requestKey: `job/role/list/${projectId}`,
      isInterruptive: false,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const getApplicantsByRole = (roleId: string, isRecommended: boolean) =>
    axios.get(
      `${jobUrl}/role/${roleId}/applicants?isRecommended=${isRecommended}`,
      {
        requestKey: `job/applicant/list?isRecommended=${isRecommended}`,
        isInterruptive: false,
        withCredentials: true,
        feedback: {
          failure: true,
        },
      }
    );

  const getApplicationsByTalent = (
    { type, withdraw }: { type: string; withdraw?: boolean },
    key?: string
  ): Promise<{
    data: (JobHistory & { loading: boolean })[];
    key: string;
  }> =>
    axios.get(`${jobUrl}/applications/${type}`, {
      requestKey: `job/application/list?key=${key}`,
      isInterruptive: false,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: {
        failure: true,
      },
      params: {
        withdraw,
        key,
      },
    });

  const updateApplicantStatus = ({
    roleId,
    talentId,
    status,
  }: {
    roleId: string;
    talentId: string;
    status: string;
  }) =>
    axios.put(
      `${jobUrl}/applicant/status/update`,
      {
        roleId,
        talentId,
        status,
      },
      {
        requestKey: 'job/applicant/status/update',
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          success: 'Applicant status updated',
          failure: true,
        },
      }
    );

  const applyJob = ({ roleId }: { roleId?: string }) =>
    axios.put(
      `${jobUrl}/role/apply`,
      {
        roleId,
      },
      {
        requestKey: `job/apply/${roleId}`,
        checkTokenValidity: true,
        withCredentials: true,
        feedback: {
          success: 'Job applied',
          failure: true,
        },
      }
    );

  const batchGetProjectRole = (payload: {
    roleIds: string[];
    projectIds: string[];
  }): Promise<{
    roles: Array<{ id: string; name: string }>;
    projects: Array<{ id: string; title: string }>;
  }> =>
    axios.post(`${jobUrl}/project/role/batch/get`, payload, {
      requestKey: `job/project/role/batch/get`,
      isInterruptive: false,
      withCredentials: true,
      feedback: { failure: true },
    });

  const createProject = (payload: ProjectPayload) =>
    axios.post(`${jobUrl}/project/create`, payload, {
      requestKey: `job/project/create`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Creating project',
        success: 'project created success',
        failure: true,
      },
    });

  const updateProject = (payload: ProjectPayload, projectId: string) =>
    axios.put(`${jobUrl}/project/${projectId}`, payload, {
      requestKey: `project/update/${projectId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating project detail',
        success: 'project update success',
        failure: true,
      },
    });

  const deleteProject = ({ projectId }: { projectId?: string }) =>
    axios.put(
      `${jobUrl}/project/${projectId}/disable`,
      {},
      {
        requestKey: `project/disabled/${projectId}`,
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          loading: 'Deleting Project',
          success: 'project deleted success',
          failure: true,
        },
      }
    );

  const createRole = (payload: RolePayload) =>
    axios.post(`${jobUrl}/role/create`, payload, {
      requestKey: `job/role/create`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Creating role',
        success: 'role created success',
        failure: true,
      },
    });

  const updateRole = (payload: RolePayload, roleId: string) =>
    axios.put(`${jobUrl}/role/${roleId}`, payload, {
      requestKey: `role/update/${roleId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating role detail',
        success: 'role update success',
        failure: true,
      },
    });

  const deleteRole = ({ roleId }: { roleId?: string }) =>
    axios.put(
      `${jobUrl}/role/${roleId}/disable`,
      {},
      {
        requestKey: `role/disabled/${roleId}`,
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          loading: 'Deleting role',
          success: 'role deleted success',
          failure: true,
        },
      }
    );

  const publishProject = (projectId: string, publish: boolean) =>
    axios.patch(
      `${jobUrl}/project/${projectId}/publish`,
      { publish },
      {
        requestKey: `project/publish/${projectId}`,
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          success: `Project successfully ${
            publish ? 'published' : 'unpublished'
          }`,
          failure: true,
        },
      }
    );

  const updateApplicantRecommend = ({
    roleId,
    talentId,
    isRecommended,
  }: {
    roleId: string;
    talentId: string;
    isRecommended: boolean;
  }) =>
    axios.put(
      `${jobUrl}/applicant/recommend/update`,
      {
        roleId,
        talentId,
        isRecommended,
      },
      {
        requestKey: 'job/applicant/recommend/update',
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          success: 'Applicant recommend updated',
          failure: true,
        },
      }
    );

  const getApplicant = (roleId: string, talentId: string) =>
    axios.get(`${jobUrl}/applicant/${roleId}/${talentId}`, {
      requestKey: `job/applicant/${roleId}/${talentId}/get`,
      isInterruptive: true,
      withCredentials: true,
      feedback: { failure: true },
    });

  return {
    getProject,
    getProjectsByHirer,
    getRolesByProject,
    getApplicantsByRole,
    updateApplicantStatus,
    getAllActiveProjects,
    getRole,
    getApplicationsByTalent,
    applyJob,
    batchGetProjectRole,
    createProject,
    updateProject,
    deleteProject,
    createRole,
    updateRole,
    deleteRole,
    publishProject,
    updateApplicantRecommend,
    getApplicant,
  };
};
