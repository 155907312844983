import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';

const { enquiry: enquiryUrl } = serviceDomain;

export const useEnquiryService = () => {
  const axios = useAxios();

  const createEnquiry = async (enquiryForm: unknown) =>
    axios.post(`${enquiryUrl}/enquiry`, enquiryForm, {
      requestKey: 'enquiry/create',
      isInterruptive: true,
      feedback: {
        failure: true,
      },
    });

  return { createEnquiry };
};
