import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import { Talent } from '../helper/type';

const { talent: talentUrl } = serviceDomain;

type UpdateProfilePayload = Partial<Talent>;

export const useTalentService = () => {
  const axios = useAxios();

  const updateProfile = (payload: UpdateProfilePayload, id: string) => {
    return axios.patch(`${talentUrl}/profile/${id}`, payload, {
      requestKey: 'talent/profile/update',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating your profile',
        success: 'Profile updated',
        failure: true,
      },
    });
  };

  const getProfile = (id: string) =>
    axios.get(`${talentUrl}/profile/${id}`, {
      requestKey: 'talent/profile/get',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Fetching talent profile',
        failure: true,
      },
    });

  const getSelfProfile = (id: string) =>
    axios.get(`${talentUrl}/profile/${id}/self`, {
      requestKey: 'talent/self-profile/get',
      isInterruptive: true,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: {
        loading: 'Fetching talent profile',
        failure: true,
      },
    });

  const getFullProfile = (id: string) =>
    axios.get(`${talentUrl}/profile/${id}/full`, {
      requestKey: 'talent/full-profile/get',
      isInterruptive: true,
    });

  const uploadFile = async (file: File, filename: string) => {
    const { data } = await axios.get(`${talentUrl}/upload`, {
      requestKey: `talent/profile/get-upload-url/${filename}`,
      params: {
        f: filename,
      },
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Upload failed',
      },
    });

    const { fields, key } = data;
    const formData = new FormData();
    formData.append('key', key);
    Object.entries(fields).forEach(([k, v]) => {
      formData.append(k, v as string);
    });
    formData.append('file', file);

    const url = data.url;

    return axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      requestKey: `talent/profile/upload/${filename}`,
      isInterruptive: true,
      withCredentials: false,
      feedback: {
        failure: true,
      },
    });
  };

  const batchGetTalent = (payload: { talentIds: string[] }) =>
    axios.post(`${talentUrl}/batch/get`, payload, {
      requestKey: 'talent/batch/get',
      isInterruptive: false,
      withCredentials: true,
      feedback: { failure: true },
    });

  return {
    updateProfile,
    getProfile,
    getSelfProfile,
    getFullProfile,
    uploadFile,
    batchGetTalent,
  };
};
