import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import {
  Comment,
  PendingRating,
  Rating,
  RatingStats,
  Reply,
} from '../helper/type';

const { rating: ratingUrl } = serviceDomain;

export const useRatingService = () => {
  const axios = useAxios();

  const createPendingRating = (body: PendingRating) =>
    axios.post(`${ratingUrl}/pending`, body, {
      requestKey: 'rating/pending/create',
      isInterruptive: true,
      withCredentials: true,
      feedback: { failure: true },
    });

  const listPendingToRate = (
    hirerId: string,
    key?: string
  ): Promise<{ data: PendingRating[]; key: string }> =>
    axios.get(`${ratingUrl}/pending`, {
      requestKey: 'rating/pending/list',
      isInterruptive: true,
      withCredentials: true,
      feedback: { failure: true },
      params: { k: hirerId, key },
    });

  const createRating = (
    rating: Pick<
      Rating,
      | 'talentId'
      | 'roleId'
      | 'hirerId'
      | 'userId'
      | 'projectId'
      | 'hireAgain'
      | 'authorCompanyName'
      | 'authorUserName'
      | 'authorProfilePicThumb'
    > & {
      shootingDate: string;
    }
  ): Promise<Rating> =>
    axios.post(`${ratingUrl}/rating`, rating, {
      requestKey: 'rating/rating/create',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Submitting...',
        success: 'Rating submitted',
        failure: true,
      },
    });

  const updateRating = (
    talentId: string,
    roleId: string,
    rating: Partial<Rating>
  ): Promise<Rating> =>
    axios.patch(`${ratingUrl}/rating/${talentId}/${roleId}`, rating, {
      requestKey: 'rating/rating/update',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating...',
        success: 'Rating updated',
        failure: true,
      },
    });

  const listRatingsByProject = (projectId: string): Promise<Rating[]> =>
    axios.get(`${ratingUrl}/rating/project/${projectId}`, {
      requestKey: `rating/project/${projectId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to load ratings at the moment',
      },
    });

  const getRating = (
    talentId: string,
    roleId: string,
    includeComments?: boolean
  ): Promise<Rating | undefined> =>
    axios.get(`${ratingUrl}/rating/${talentId}/${roleId}`, {
      requestKey: `rating/rating/${talentId}/${roleId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to load rating at the moment',
      },
      params: {
        ...(includeComments && { comment: '1' }),
      },
    });

  const createReply = (reply: Partial<Reply>) =>
    axios.post(`${ratingUrl}/reply`, reply, {
      requestKey: 'rating/reply/create',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Submitting...',
        success: 'Reply submitted',
        failure: true,
      },
    });

  const updateReply = (ratingId: string, replyId: string, reply: string) =>
    axios.patch(
      `${ratingUrl}/reply/${ratingId}/${replyId}`,
      { reply },
      {
        requestKey: 'rating/reply/update',
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          loading: 'Updating...',
          success: 'Reply updated',
          failure: true,
        },
      }
    );

  const deleteReply = (ratingId: string, replyId: string) =>
    axios.delete(`${ratingUrl}/reply/${ratingId}/${replyId}`, {
      requestKey: 'rating/reply/delete',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Deleting...',
        success: 'Reply deleted',
        failure: true,
      },
    });

  const getRatingStats = (talentId: string) =>
    axios.get<RatingStats>(`${ratingUrl}/stats/${talentId}`, {
      requestKey: 'rating/stats/get',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to load rating stats at the moment.',
      },
    });

  const createComment = (payload: Partial<Comment>) =>
    axios.post(`${ratingUrl}/comment`, payload, {
      requestKey: 'rating/comment/create',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Submitting...',
        success: 'Comment submitted',
        failure: true,
      },
    });

  const listRatingsByTalent = (
    talentId: string,
    key?: string
  ): Promise<{ data: Rating[]; key?: string }> =>
    axios.get(`${ratingUrl}/rating/talent/${talentId}`, {
      requestKey: 'rating/talent/list',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to load ratings at the moment.',
      },
      params: { key },
    });

  const updateComment = (
    ratingId: string,
    commentId: string,
    comment: string
  ) =>
    axios.patch(
      `${ratingUrl}/comment/${ratingId}/${commentId}`,
      { comment },
      {
        requestKey: 'rating/comment/update',
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          loading: 'Updating...',
          success: 'Comment updated',
          failure: true,
        },
      }
    );

  const deleteComment = (ratingId: string, commentId: string) =>
    axios.delete(`${ratingUrl}/comment/${ratingId}/${commentId}`, {
      requestKey: 'rating/comment/delete',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Deleting...',
        success: 'Comment deleted',
        failure: true,
      },
    });

  const getPendingToRate = (
    hirerId: string,
    talentId: string,
    roleId: string
  ): Promise<PendingRating> =>
    axios.get(`${ratingUrl}/pending/${talentId}/${roleId}`, {
      requestKey: 'rating/pending/get',
      isInterruptive: true,
      withCredentials: true,
      feedback: { failure: true },
      params: { k: hirerId },
    });

  return {
    createComment,
    createPendingRating,
    createRating,
    createReply,
    deleteComment,
    deleteReply,
    getPendingToRate,
    getRating,
    getRatingStats,
    listPendingToRate,
    listRatingsByProject,
    listRatingsByTalent,
    updateComment,
    updateRating,
    updateReply,
  };
};
