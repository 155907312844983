// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

type PartialNotification = Partial<{
  registration: ServiceWorkerRegistration;
  subscription: PushSubscription | null;
}>;

export async function register(config: Config) {
  console.log(`service worker register, env -> ${process.env.NODE_ENV}`);
  console.log(navigator);
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    console.log('there is a servicework');

    window?.addEventListener('load', () => {
      registerValidSW('/service-worker.js', config);
    });
  }
}

async function registerValidSW(swUrl: string, config?: Config) {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);
    console.log('registration in progress');
    console.log(registration);

    registration.onupdatefound = () => {
      console.log('on update found');
      console.log(registration.installing);

      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }

      installingWorker.onstatechange = () => {
        console.log('on state change found');

        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older
            // content until all client tabs are closed.
            console.log(
              'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://cra.link/PWA.'
            );

            // Execute callback
            if (config && config.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            // At this point, everything has been precached.
            // It's the perfect time to display a
            // "Content is cached for offline use." message.
            console.log('Content is cached for offline use.');

            // Execute callback
            if (config && config.onSuccess) {
              config.onSuccess(registration);
            }
          }
        }
      };
    };
  } catch (error) {
    console.error('Error during service worker registration:', error);
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export async function registerSubscribeNotification(
  data: Record<string, unknown>
) {
  console.log('registerSubscribeNotification >>');

  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    throw new Error('Notification permission not granted');
  }

  const { registration, subscription } = await getSubscription();

  if (registration && registration.active && !subscription) {
    console.log('data.vapidPublicKey', data.vapidPublicKey);
    registration.active.postMessage({ ...data, type: 'SUBSCRIBE_PUSH' }); // handle in self.addEventListener('message')
  }
}

export async function getSubscription(): Promise<PartialNotification> {
  const registration = await navigator.serviceWorker.getRegistration();
  console.log('registration >>', registration);
  if (registration?.active) {
    const subscription = await registration.pushManager.getSubscription();
    console.log('subscription >>', subscription);
    return { registration, subscription };
  }

  return {};
}
