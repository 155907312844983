import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import LoginPage from '../page/Auth/Login/Login';
import RegisterPage from '../page/Auth/Register/Register';
import ForgotPasswordPage from '../page/Auth/ForgotPassword/ForgotPassword';
import ResetPasswordPage from '../page/Auth/ResetPassword/ResetPassword';
import VerifyAccountPage from '../page/Auth/VerifyAccount/VerifyAccount';
import ClaimProfilePage from '../page/Auth/ClaimProfile/ClaimProfile';
import WhichUserPage from '../page/Auth/WhichUser/WhichUser';
import LogoutPage from '../page/Auth/Logout/Logout';

const authRoutes: RouteProps[] = [
  {
    path: '/auth/talent/claim',
    component: ClaimProfilePage,
  },
  {
    path: '/auth/:userType(talent|hirer)/login',
    component: LoginPage,
  },
  {
    path: '/auth/:userType(talent|hirer)/register',
    component: RegisterPage,
  },
  {
    path: '/auth/:userType(talent|hirer)/forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path: '/auth/:userType(talent|hirer)/confirm-password-reset',
    component: ResetPasswordPage,
  },
  {
    path: '/auth/:userType(talent|hirer)/verify-account',
    component: VerifyAccountPage,
  },
  {
    path: '/auth/:userType(talent|hirer)/logout',
    component: LogoutPage,
  },
  {
    path: '/auth/role',
    component: WhichUserPage,
  },
  {
    path: '/auth',
    exact: true,
    render: () => <Redirect to='/auth/role' />,
  },
];

export default authRoutes;
