import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { HIRER_ROUTES } from './constant';
import { featureToggle } from '../../config/app.config';
import HirerProjectsPage from '../page/Hirer/Project/Projects';
import HirerRolesPage from '../page/Hirer/Roles';
import HirerApplicantsPage from '../page/Hirer/Applicants';
import ScheduleDetailPage from '../page/Hirer/Schedule/ScheduleDetail';
import SchedulesPage from '../page/Hirer/Schedule/Schedules';
import HirerAgendaPage from '../page/Hirer/Agenda/Agenda';
import HirerProjectFormPage from '../page/Hirer/Project/Form';
import HirerRoleFormPage from '../page/Hirer/Role/Form';
import HirerLoginPage from '../page/Hirer/Login/Login';
import HirerRegisterPage from '../page/Hirer/Register/Register';
import HirerRegisterCompanyPage from '../page/Hirer/Register/RegisterCompany';
import HirerAuthPage from '../page/Hirer/Auth/Auth';
import HirerProfilePage from '../page/Hirer/Profile/Profile';
import HirerNewUserPage from '../page/Hirer/Register/NewUser';
import HirerClaimProfilePage from '../page/Hirer/ClaimProfile/ClaimProfile';
import HirerLogoutPage from '../page/Hirer/Logout/Logout';
import RateTalentPage from '../page/Hirer/RateTalent/RateTalent';
import RateTalentCompletePage from '../page/Hirer/RateTalent/RateTalentComplete';
import ViewRatingsPage from '../page/Hirer/RateTalent/ViewRatings';
import ViewCommentsPage from '../page/Hirer/RateTalent/ViewComments';
import InvoiceDetailsPage from '../page/Hirer/Invoice/InvoiceDetails';
import PaymentFeedbackPage from '../page/Hirer/Invoice/PaymentFeedback';

type Routes = RouteProps & {
  requireLogin?: boolean;
};

const ratingRoutes: Routes[] = [
  {
    path: HIRER_ROUTES.RATE_TALENT_COMPLETE,
    component: RateTalentCompletePage,
    requireLogin: true,
  },
  {
    path: '/hirer/rate/:projectId/:roleId',
    component: RateTalentPage,
    requireLogin: true,
  },
  {
    path: '/hirer/rating/talent/:talentId/role/:roleId/comments',
    component: ViewCommentsPage,
    requireLogin: true,
  },
  {
    path: '/hirer/project/:projectId/ratings',
    component: ViewRatingsPage,
    requireLogin: true,
  },
];

const invoiceRoutes: Routes[] = [
  {
    path: '/hirer/project/:projectId/invoice/:invoiceId',
    component: InvoiceDetailsPage,
    requireLogin: true,
  },
  {
    path: '/hirer/payment/:status(successful|unsuccessful)',
    component: PaymentFeedbackPage,
    requireLogin: true,
  },
];

const hirerRoutes: Routes[] = [
  ...(featureToggle.rating ? ratingRoutes : []),
  ...(featureToggle.invoice ? invoiceRoutes : []),

  {
    path: HIRER_ROUTES.AGENDA,
    component: HirerAgendaPage,
    requireLogin: true,
  },
  {
    path: HIRER_ROUTES.CLAIM_PROFILE,
    component: HirerClaimProfilePage,
  },
  {
    path: '/hirer/project/:projectId/role/:roleId',
    component: HirerApplicantsPage,
    requireLogin: true,
  },
  {
    path: '/hirer/project/:projectId/role',
    component: HirerRoleFormPage,
  },
  {
    path: '/hirer/edit/project/:projectId/role/:roleId',
    component: HirerRoleFormPage,
  },
  {
    path: '/hirer/project/:projectId',
    component: HirerRolesPage,
  },
  {
    path: '/hirer/project',
    component: HirerProjectFormPage,
  },
  {
    path: '/hirer/edit/project/:projectId',
    component: HirerProjectFormPage,
  },
  {
    path: HIRER_ROUTES.LOGIN,
    component: HirerLoginPage,
  },
  {
    path: HIRER_ROUTES.LOGOUT,
    component: HirerLogoutPage,
  },
  {
    path: HIRER_ROUTES.REGISTER_COMPANY,
    component: HirerRegisterCompanyPage,
    requireLogin: true,
  },
  {
    path: HIRER_ROUTES.REGISTER,
    component: HirerRegisterPage,
  },
  {
    path: HIRER_ROUTES.NEW_USER,
    component: HirerNewUserPage,
    requireLogin: true,
  },
  {
    path: `${HIRER_ROUTES.AUTH}/:mode(new)?`,
    component: HirerAuthPage,
  },
  {
    path: HIRER_ROUTES.PROFILE,
    component: HirerProfilePage,
    requireLogin: true,
  },
  {
    path: HIRER_ROUTES.DASHBOARD,
    component: HirerProjectsPage,
    requireLogin: true,
  },
  {
    path: '/hirer/schedule/:type(create|edit)/:scheduleId?',
    component: ScheduleDetailPage,
    requireLogin: true,
  },
  {
    path: HIRER_ROUTES.SCHEDULE_LISTING,
    component: SchedulesPage,
    requireLogin: true,
  },
  {
    path: '/hirer',
    exact: true,
    render: () => <Redirect to={HIRER_ROUTES.DASHBOARD} />,
    requireLogin: true,
  },
];

export default hirerRoutes;
