import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import { Activity, Schedule } from '../helper/type';

const { activity: activityUrl } = serviceDomain;

export type AddApplicantViewCountPayload = Pick<
  Activity,
  | 'hirerId'
  | 'roleId'
  | 'talentId'
  | 'applicationId'
  | 'projectTitle'
  | 'roleName'
>;
export type CreateActivityPayload = Pick<
  Activity,
  | 'roleId'
  | 'talentId'
  | 'hirerId'
  | 'applicationId'
  | 'projectTitle'
  | 'roleName'
  | 'actionType'
>;
export type ConfirmApplicantPayload = Pick<
  Activity,
  | 'hirerId'
  | 'roleId'
  | 'talentId'
  | 'applicationId'
  | 'projectTitle'
  | 'roleName'
  | 'projectId'
  | 'talentPhoneNumber'
  | 'talentPhoneNumberPrefix'
  | 'scheduledDatetime'
> & { talentName: string };

export type ListRoleTalentActivityPayload = {
  roleId: string;
  talentId?: string;
  isInterruptive?: boolean;
  key?: string;
};

export type TalentReplyConfirmationPayload = Pick<
  Activity,
  | 'activityId'
  | 'hirerId'
  | 'roleId'
  | 'talentId'
  | 'applicationId'
  | 'scheduledDatetime'
  | 'actionType'
> & { replyStatus: 'SCHEDULED' | null; talentNeeded?: number };

export type HirerCancelCastingPayload = Pick<
  Activity,
  'activityId' | 'hirerId' | 'roleId' | 'talentId' | 'cancelReason'
>;

export type HirerEditCastingPayload = Pick<
  Activity,
  | 'activityId'
  | 'hirerId'
  | 'roleId'
  | 'talentId'
  | 'castingBrief'
  | 'virtualMeetingLink'
  | 'picContact'
  | 'picContactPrefix'
  | 'castingLocation'
  | 'castingLocationLng'
  | 'castingLocationLat'
  | 'submitDeadline'
  | 'updateLog'
>;

export type HirerInviteCastingPayload = Pick<
  Activity,
  | 'hirerId'
  | 'roleId'
  | 'projectId'
  | 'projectTitle'
  | 'roleName'
  | 'scheduleId'
  | 'actionType'
  | 'duration'
  | 'castingType'
  | 'castingBrief'
  | 'virtualMeetingLink'
  | 'submitDeadline'
> & {
  talents: Array<
    Pick<
      Activity,
      | 'talentId'
      | 'applicationId'
      | 'talentPhoneNumber'
      | 'talentPhoneNumberPrefix'
    > & { talentName: string }
  >;
  projectClosingDate: string;
};

export type TalentReplyInvitationPayload = Pick<
  Activity,
  | 'activityId'
  | 'hirerId'
  | 'roleId'
  | 'applicationId'
  | 'scheduleId'
  | 'scheduledDatetime'
  | 'castingType'
  | 'selfCastingVideo'
  | 'duration'
> & { reply: 'accept' | 'reject' | 'replied' };

export type TalentWithdrawApplicationPayload = Pick<
  Activity,
  | 'hirerId'
  | 'roleId'
  | 'applicationId'
  | 'projectId'
  | 'projectTitle'
  | 'roleName'
> & { withdrawReason: string };

export const useActivityService = () => {
  const axios = useAxios();

  const addApplicantViewCount = (
    payload: AddApplicantViewCountPayload,
    viewType: string
  ) =>
    axios.put(`${activityUrl}/application/view-count/${viewType}`, payload, {
      requestKey: 'activity/application/view-count/add',
      isInterruptive: false,
      withCredentials: true,
    });

  const createActivity = (payload: CreateActivityPayload) =>
    axios.put(
      `${activityUrl}/role/${payload.roleId}/talent/${payload.talentId}`,
      payload,
      {
        requestKey: `activity/role/${payload.roleId}/talent/${payload.talentId}/put`,
        isInterruptive: false,
        withCredentials: true,
      }
    );

  const confirmApplicant = (payload: ConfirmApplicantPayload) =>
    axios.post(`${activityUrl}/application/confirm`, payload, {
      requestKey: 'activity/application/confirm',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating application status',
        success: 'Application status updated',
        failure: true,
      },
    });

  const talentReplyConfirmation = (payload: TalentReplyConfirmationPayload) =>
    axios.patch(`${activityUrl}/talent/confirmation/reply`, payload, {
      requestKey: 'activity/talent/confirmation/reply',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating application status',
        success: 'Application status updated',
        failure: true,
      },
    });

  const listTalentActivities = (
    type: 'all' | 'pending' | 'scheduled' | 'other',
    talentId: string,
    key?: string
  ) =>
    axios.get(`${activityUrl}/talent/${type}`, {
      requestKey: `activity/talent/${type}`,
      isInterruptive: false,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: { failure: true },
      params: { key, talentId },
    });

  const saveSchedule = (schedule: Partial<Schedule>, scheduleId?: string) =>
    axios.put(
      `${activityUrl}/schedule${scheduleId ? `/${scheduleId}` : ''}`,
      schedule,
      {
        requestKey: `activity/schedule/put${
          scheduleId ? `/${scheduleId}` : ''
        }`,
        isInterruptive: true,
        withCredentials: true,
        feedback: {
          loading: 'Saving schedule',
          success: 'Schedule saved',
          failure: true,
        },
      }
    );

  const listHirerSchedules = (hirerId: string) =>
    axios.get(`${activityUrl}/schedules/${hirerId}`, {
      requestKey: `activity/schedules/${hirerId}`,
      isInterruptive: false,
      withCredentials: true,
      feedback: { failure: true },
    });

  const getHirerSchedule = (scheduleId: string) =>
    axios.get(`${activityUrl}/schedule/${scheduleId}`, {
      requestKey: `activity/schedule/get/${scheduleId}`,
      isInterruptive: false,
      withCredentials: true,
      feedback: { failure: true },
    });

  const hirerCancelCasting = (payload: HirerCancelCastingPayload) =>
    axios.patch(`${activityUrl}/hirer/casting/cancel`, payload, {
      requestKey: `activity/hirer/casting/cancel`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        success: 'Casting session cancelled',
        failure: true,
      },
    });

  const hirerEditCasting = (payload: HirerEditCastingPayload) =>
    axios.patch(`${activityUrl}/hirer/casting/edit`, payload, {
      requestKey: `activity/hirer/casting/edit`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating casting info',
        success: 'Casting info updated',
        failure: true,
      },
    });

  const hirerInviteCasting = (payload: HirerInviteCastingPayload) =>
    axios.put(`${activityUrl}/hirer/casting/invite`, payload, {
      requestKey: `activity/hirer/casting/invite`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Sending invitation',
        success: 'Invitation sent',
        failure: true,
      },
    });

  const talentReplyInvitation = (
    talentId: string,
    payload: TalentReplyInvitationPayload
  ) =>
    axios.patch(`${activityUrl}/talent/invitation/reply`, payload, {
      requestKey: `activity/talent/invitation/reply`,
      isInterruptive: true,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: {
        loading: 'Sending reply',
        success: `Replied invitation`,
        failure: true,
      },
      params: { talentId },
    });

  const getTalentTakenTimeslot = (scheduleId: string) =>
    axios.get(`${activityUrl}/schedule/taken/${scheduleId}`, {
      requestKey: `activity/schedule/taken/${scheduleId}`,
      isInterruptive: false,
      withCredentials: true,
      feedback: { failure: true },
    });

  const getActivity = (activityId: string) =>
    axios.get(`${activityUrl}/activity/${activityId}`, {
      requestKey: `activity/get/${activityId}`,
      isInterruptive: false,
      withCredentials: true,
      feedback: { failure: true },
    });

  const listRoleTalentActivity = ({
    roleId,
    talentId,
    isInterruptive = false,
    key,
  }: ListRoleTalentActivityPayload): Promise<{
    data: Activity[];
    key?: string;
  }> =>
    axios.get(
      `${activityUrl}/role/${roleId}/talent${talentId ? `/${talentId}` : ''}`,
      {
        requestKey: `activity/role/talent/get`,
        isInterruptive,
        withCredentials: true,
        feedback: { failure: true },
        params: { key },
      }
    );

  const listHirerAgenda = (
    hirerId: string,
    type: 'upcoming' | 'past',
    key?: string
  ) =>
    axios.get(`${activityUrl}/hirer/${hirerId}/agenda/${type}`, {
      requestKey: `activity/hirer/${hirerId}/agenda/${type}`,
      isInterruptive: false,
      withCredentials: true,
      feedback: { failure: true },
      params: { key },
    });

  const listTalentAgenda = (
    type: 'upcoming' | 'past',
    talentId: string,
    key?: string
  ) =>
    axios.get(`${activityUrl}/talent/agenda/${type}`, {
      requestKey: `activity/talent/agenda/${type}`,
      isInterruptive: false,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: { failure: true },
      params: { key, talentId },
    });

  const talentWithdrawApplication = (
    talentId: string,
    payload: TalentWithdrawApplicationPayload
  ) =>
    axios.put(`${activityUrl}/talent/application/withdraw`, payload, {
      requestKey: `activity/talent/application/withdraw`,
      isInterruptive: false,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: {
        failure: true,
      },
      params: { talentId },
    });

  return {
    addApplicantViewCount,
    createActivity,
    confirmApplicant,
    listTalentActivities,
    saveSchedule,
    listHirerSchedules,
    getHirerSchedule,
    hirerCancelCasting,
    hirerEditCasting,
    hirerInviteCasting,
    talentReplyConfirmation,
    talentReplyInvitation,
    getTalentTakenTimeslot,
    getActivity,
    listRoleTalentActivity,
    listHirerAgenda,
    listTalentAgenda,
    talentWithdrawApplication,
  };
};
