import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import { AccessTime } from '../helper/type';

const { notification: notificationUrl } = serviceDomain;

type UpdateAccessTimePayload = Partial<AccessTime>;

export const useNotificationService = () => {
  const axios = useAxios();

  const updateAccessTime = (
    payload: UpdateAccessTimePayload,
    talentId: string
  ) =>
    axios.put(
      `${notificationUrl}/subscription/${talentId}/lastAccess`,
      payload,
      {
        requestKey: `subscription/lastAccess/${talentId}`,
        withCredentials: true,
      }
    );

  return {
    updateAccessTime,
  };
};
