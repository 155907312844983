import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';

const { location: locationUrl } = serviceDomain;

export type GeoSearchResult = {
  address: string;
  distance: number;
  lng: number;
  lat: number;
};

export const useLocationService = () => {
  const axios = useAxios();

  const getMapConfig = (): Promise<{
    auth: string;
    mapStyle: string;
  }> =>
    axios.get(`${locationUrl}/map/config`, {
      requestKey: 'location/map/config',
      isInterruptive: false,
      withCredentials: true,
    });

  const searchLngLat = (lng: number, lat: number): Promise<GeoSearchResult[]> =>
    axios.get(`${locationUrl}/search/point`, {
      requestKey: 'location/search/point',
      isInterruptive: false,
      withCredentials: true,
      params: { lng, lat },
    });

  const searchAddress = (addr: string): Promise<GeoSearchResult[]> =>
    axios.get(`${locationUrl}/search/addr`, {
      requestKey: 'location/search/addr',
      isInterruptive: false,
      withCredentials: true,
      params: { txt: addr },
    });

  return {
    getMapConfig,
    searchLngLat,
    searchAddress,
  };
};
