import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ModalName, ModalConfig } from '../component/Modal';
import type { InterceptorConfig } from '../helper/api';

type LoadingPayload = InterceptorConfig;
interface SetErrorPayload {
  requestKey: string;
  error: Error;
}

interface ClearErrorPayload {
  requestKey: string;
}

interface OpenModalPayload {
  modal: ModalName;
  config?: ModalConfig;
}

interface SetMenuModePayload {
  isShrank: boolean;
}

interface SetFlyoutOpenPayload {
  isOpen: boolean;
}

interface SetActivityFlyoutOpenPayload {
  isOpen: boolean;
}

interface SetBannerDismissedPayload {
  isDismissed: boolean;
}

type BrowserDetail = {
  browserName: string;
  browserVersion: string;
  os: string;
  isMobile: boolean;
};

interface AppState {
  loadStack: string[];
  intLoadStack: string[];
  error: Record<string, any>;
  isMenuShrank: boolean;
  isMenuFlyoutOpen: boolean;
  isActivityFlyoutOpen: boolean;
  isBannerDismissed: boolean;
  isInstallBannerDismissed: boolean;
  modal: {
    name: ModalName | null;
    config: Partial<ModalConfig>;
  };
  browser: BrowserDetail;
  isPWA: boolean;
  promptEvent: any;
  isSubscribeNotif: boolean;
  countRejectPromptInstall: number;
  isForceDisplayPrompt: boolean;
}

const initialState = {
  loadStack: [],
  intLoadStack: [],
  error: {},
  isMenuShrank: false,
  isMenuFlyoutOpen: false,
  isActivityFlyoutOpen: false,
  modal: {
    name: null,
    config: {},
  },
  isBannerDismissed: false,
  isInstallBannerDismissed: false,
  browser: {
    browserName: '',
    browserVersion: '',
    os: '',
    isMobile: false,
  },
  isPWA: false,
  promptEvent: null,
  isSubscribeNotif: false,
  countRejectPromptInstall: 0,
  isForceDisplayPrompt: false,
} as AppState;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { requestKey, isInterruptive } = action.payload;
      state[isInterruptive ? 'intLoadStack' : 'loadStack'].push(requestKey);
    },
    endLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { requestKey, isInterruptive } = action.payload;
      const stack = isInterruptive ? 'intLoadStack' : 'loadStack';
      state[stack] = state[stack].filter((key) => key !== requestKey);
    },
    setError: (state, action: PayloadAction<SetErrorPayload>) => {
      const { requestKey, error } = action.payload;
      state.error = {
        ...state.error,
        [requestKey]: error,
      };
    },
    clearError: (state, action: PayloadAction<ClearErrorPayload>) => {
      delete state.error[action.payload.requestKey];
    },
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      const { modal, config = {} } = action.payload;
      state.modal = {
        name: modal,
        config,
      };
    },
    closeModal: (state) => {
      state.modal = {
        ...state.modal,
        name: null,
      };
    },
    setMenuMode: (state, action: PayloadAction<SetMenuModePayload>) => {
      state.isMenuShrank = action.payload.isShrank;
    },
    setFlyoutOpen: (state, action: PayloadAction<SetFlyoutOpenPayload>) => {
      state.isMenuFlyoutOpen = action.payload.isOpen;
    },
    setActivityFlyoutOpen: (
      state,
      action: PayloadAction<SetActivityFlyoutOpenPayload>
    ) => {
      state.isActivityFlyoutOpen = action.payload.isOpen;
    },
    setBannerDismissed: (
      state,
      action: PayloadAction<SetBannerDismissedPayload>
    ) => {
      state.isBannerDismissed = action.payload.isDismissed;
    },
    setInstallBannerDismissed: (
      state,
      action: PayloadAction<SetBannerDismissedPayload>
    ) => {
      state.isInstallBannerDismissed = action.payload.isDismissed;
    },
    setIsPWA: (state, action: PayloadAction<{ isPWA: boolean }>) => {
      state.isPWA = action.payload.isPWA;
    },
    setBrowserDetail: (state, action: PayloadAction<BrowserDetail>) => {
      state.browser = action.payload;
    },

    setPromptEvent: (state, action: PayloadAction<any>) => {
      state.promptEvent = action.payload;
    },
    setIsSubscribeNotif: (
      state,
      action: PayloadAction<{ isSubcribe: boolean }>
    ) => {
      state.isSubscribeNotif = action.payload.isSubcribe;
    },
    setCountRejectPromptInstall: (
      state,
      action: PayloadAction<{ count: number }>
    ) => {
      state.countRejectPromptInstall = action.payload.count;
      localStorage.setItem(
        '_countRejectPromptInstall',
        action.payload.count.toString()
      );
    },

    setForceDisplayPrompt: (
      state,
      action: PayloadAction<{ isForceDisplay: boolean }>
    ) => {
      state.isForceDisplayPrompt = action.payload.isForceDisplay;
    },
  },
});

export const {
  startLoading,
  endLoading,
  setError,
  clearError,
  openModal,
  closeModal,
  setMenuMode,
  setFlyoutOpen,
  setActivityFlyoutOpen,
  setBannerDismissed,
  setInstallBannerDismissed,
  setIsPWA,
  setBrowserDetail,
  setPromptEvent,
  setIsSubscribeNotif,
  setCountRejectPromptInstall,
  setForceDisplayPrompt,
} = appSlice.actions;

export default appSlice.reducer;
