import React from 'react';
import { RouteProps } from 'react-router-dom';
import AuthPage from '../page/Auth/Auth';
import TalentProfilePage from '../page/Talent/Profile';
import ProjectPage from '../page/Project/Project';
import HirerBasePage from '../page/Hirer/Base';
import HomePage from '../page/Website/Home';
import PrivacyPage from '../page/Website/Privacy';
import TermsPage from '../page/Website/Terms';
import FaqPage from '../page/Website/Faq';
import AboutUsPage from '../page/Website/AboutUs';
import TalentDashboardPage from '../page/Talent/Dashboard/TalentDashboard';
import DirectoryPage from '../page/Job/Directory/Directory';
import ActivateTelegram from '../page/Instructions/ActivateTelegram';
import AdminBasePage from '../page/Admin/Base';
import EnquirySubmittedPage from '../page/Website/EnquirySubmitted';
import InvalidAuthLinkPage from '../page/Website/InvalidAuthLink';

const appRoutes: RouteProps[] = [
  {
    path: '/auth',
    component: AuthPage,
  },
  {
    path: '/p/:id',
    component: ProjectPage,
  },
  {
    path: '/t/:id',
    component: TalentProfilePage,
  },
  {
    path: '/talent',
    component: TalentDashboardPage,
  },
  {
    path: '/job/directory',
    component: DirectoryPage,
  },
  {
    path: '/hirer',
    component: HirerBasePage,
  },
  {
    path: '/admin',
    component: AdminBasePage,
  },
  {
    path: '/instructions/activate-telegram',
    component: ActivateTelegram,
  },
  {
    path: '/site-policy/privacy',
    component: PrivacyPage,
  },
  {
    path: '/site-policy/terms',
    component: TermsPage,
  },
  {
    path: '/faq',
    component: FaqPage,
  },
  {
    path: '/about-us',
    component: AboutUsPage,
  },
  {
    path: '/enquiry-submitted',
    component: EnquirySubmittedPage,
  },
  {
    path: '/invalid-link',
    component: InvalidAuthLinkPage,
  },
  {
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    path: '/',
    render: () => {
      return <div>404</div>;
    },
  },
];

export default appRoutes;
