import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';

const { auth: authUrl } = serviceDomain;

type LoginPayload = {
  email: string;
  password: string;
};

type ClaimProfilePayload = {
  email: string;
  password: string;
  verificationCode: string;
  username: string;
};

type RegisterPayload = {
  email: string;
  name: string;
  password: string;
};

type VerifySignupPayload = {
  userId: string;
  code: string;
};

type ForgotPasswordPayload = {
  email: string;
};

type ResetPasswordPayload = {
  password: string;
  code: string;
  userId: string;
};

type ChangePasswordPayload = {
  oldPass: string;
  newPass: string;
  confirmPass: string;
};

type UserType = 'talent' | 'hirer';

export const useAuthService = () => {
  const axios = useAxios();

  const login = (payload: LoginPayload, userType: UserType) =>
    axios.post(`${authUrl}/${userType}/login`, payload, {
      requestKey: 'auth/login',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Logging in...',
        success: ({ name }) => `Welcome, ${name}`,
      },
    });

  const logout = () =>
    axios.get(`${authUrl}/talent/logout`, {
      requestKey: 'auth/logout',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Logging out...',
        success: 'Logged out',
        failure: true,
      },
    });

  const register = (payload: RegisterPayload, userType: UserType) =>
    axios.post(`${authUrl}/${userType}/register`, payload, {
      requestKey: 'auth/register',
      isInterruptive: true,
      withCredentials: true,
    });

  const claimProfile = (payload: ClaimProfilePayload) =>
    axios.post(`${authUrl}/talent/claim`, payload, {
      requestKey: 'auth/claim',
      isInterruptive: true,
    });

  const verifySignup = (payload: VerifySignupPayload, userType: UserType) =>
    axios.post(`${authUrl}/${userType}/verify`, payload, {
      requestKey: 'auth/verify',
      isInterruptive: true,
    });

  const forgotPassword = (payload: ForgotPasswordPayload, userType: UserType) =>
    axios.post(`${authUrl}/${userType}/password/reset`, payload, {
      requestKey: 'auth/forgotPassword',
      isInterruptive: true,
    });

  const resetPassword = (payload: ResetPasswordPayload, userType: UserType) =>
    axios.post(`${authUrl}/${userType}/password/completeReset`, payload, {
      requestKey: 'auth/completeResetPassword',
      isInterruptive: true,
    });

  const changePassword = (payload: ChangePasswordPayload, userType: UserType) =>
    axios.post(`${authUrl}/${userType}/change-password`, payload, {
      requestKey: 'auth/change-password',
      withCredentials: true,
      checkTokenValidity: true,
      isInterruptive: true,
      feedback: {
        loading: 'Changing Password...',
        success: 'Password updated',
        failure: true,
      },
    });

  const resendVerificationEmail = (email: string | undefined) =>
    axios.post(
      `${authUrl}/talent/resend/verification`,
      { email },
      {
        requestKey: 'auth/resend-verification',
        withCredentials: true,
        isInterruptive: true,
        feedback: {
          loading: 'Resending verification email...',
          success: 'Email sent',
          failure: true,
        },
      }
    );

  return {
    login,
    logout,
    register,
    claimProfile,
    verifySignup,
    forgotPassword,
    resetPassword,
    changePassword,
    resendVerificationEmail,
  };
};
