import { RouteProps } from 'react-router-dom';
import ContactInfoPage from '../page/Talent/Form/ContactInfo/ContactInfo';
import AboutMePage from '../page/Talent/Form/AboutMe/AboutMe';
import SkillPage from '../page/Talent/Form/Skill/Skill';
import ExperiencePage from '../page/Talent/Form/Experience/Experience';
import MediaPage from '../page/Talent/Form/Media/Media';
import VideoPage from '../page/Talent/Form/Video/Video';
import AgentPage from '../page/Talent/Form/Agent/Agent';
import InfluencerPage from '../page/Talent/Form/Influencer/Influencer';
import JobPreferencePage from '../page/Talent/Form/JobPreference/JobPreference';
import ProfilePicturePage from '../page/Talent/Form/ProfilePicture/ProfilePicture';
import ProfileSection from '../page/Talent/Dashboard/ProfileSection/ProfileSection';

const talentProfileFormRoutes: RouteProps[] = [
  {
    path: '/talent/profile/contact',
    component: ContactInfoPage,
  },
  {
    path: '/talent/profile/about',
    component: AboutMePage,
  },
  {
    path: '/talent/profile/skill',
    component: SkillPage,
  },
  {
    path: '/talent/profile/media',
    component: MediaPage,
  },
  {
    path: '/talent/profile/picture',
    component: ProfilePicturePage,
  },
  {
    path: '/talent/profile/video',
    component: VideoPage,
  },
  {
    path: '/talent/profile/experience',
    component: ExperiencePage,
  },
  {
    path: '/talent/profile/kol',
    component: InfluencerPage,
  },
  {
    path: '/talent/profile/agent',
    component: AgentPage,
  },
  {
    path: '/talent/profile/job-preference',
    component: JobPreferencePage,
  },
  {
    path: '/talent/profile',
    component: ProfileSection,
  },
];

export default talentProfileFormRoutes;
