import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';

const { sstAuth: sstAuthUrl, ui: uiUrl } = serviceDomain;

type GetLinkPayload = {
  authType: string;
  email: string;
  linkExpiry?: number;
  redirect: string;
  data?: Record<string, unknown>;
};

type ImpAuthPayload = {
  impkey: string;
  data?: Record<string, unknown>;
};

export const useSstAuthService = () => {
  const axios = useAxios();

  const getLink = (payload: GetLinkPayload) =>
    axios.get(`${sstAuthUrl}/link/authorize`, {
      requestKey: 'sst/auth/link/authorize',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Sending magic link to email...',
        success: 'Link sent',
      },
      params: {
        ...payload,
        redirect: payload.redirect.startsWith('http')
          ? payload.redirect
          : `${uiUrl}${payload.redirect}`,
      },
    });

  const impAuth = (payload: ImpAuthPayload) =>
    axios.post(`${sstAuthUrl}/imp/authorize`, payload, {
      requestKey: 'sst/auth/imp/authorize',
      isInterruptive: true,
      withCredentials: true,
    });

  return {
    getLink,
    impAuth,
  };
};
