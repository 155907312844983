import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import { HirerCompany, HirerUser } from '../helper/type';

const { hirer: hirerUrl } = serviceDomain;

export const useHirerService = () => {
  const axios = useAxios();

  const claimProfile = (
    payload: Partial<HirerUser> & { verificationCode: string }
  ): Promise<HirerUser> =>
    axios.post(`${hirerUrl}/claim`, payload, {
      requestKey: 'hirer/claim',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const createCompany = (
    payload: Partial<HirerCompany>
  ): Promise<HirerCompany> =>
    axios.post(`${hirerUrl}/company`, payload, {
      requestKey: 'hirer/company/create',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const createUser = (payload: Partial<HirerUser>): Promise<HirerUser> =>
    axios.post(`${hirerUrl}/user`, payload, {
      requestKey: 'hirer/user/create',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const emailExists = (email: string) =>
    axios.get(`${hirerUrl}/email/exists`, {
      requestKey: 'hirer/email/exists',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Checking email...',
        failure: true,
      },
      params: { email },
    });

  const getHirer = (hirerId: string): Promise<HirerCompany> =>
    axios.get(`${hirerUrl}/hirer/${hirerId}`, {
      requestKey: 'hirer/hirer/get',
      isInterruptive: true,
      withCredentials: true,
    });

  const getUser = (): Promise<HirerUser> =>
    axios.get(`${hirerUrl}/user`, {
      requestKey: 'hirer/user/get',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: 'Unable to retrieve user info',
      },
    });

  const listHirerUsers = (hirerId: string): Promise<HirerUser[]> =>
    axios.get(`${hirerUrl}/users/${hirerId}`, {
      requestKey: `hirer/users/${hirerId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const updateHirer = (
    hirerId: string,
    payload: Partial<HirerCompany>
  ): Promise<HirerCompany> =>
    axios.post(`${hirerUrl}/hirer/${hirerId}`, payload, {
      requestKey: 'hirer/hirer/update',
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        failure: true,
      },
    });

  const updateUser = ({
    hirerId,
    userId,
    ...payload
  }: Partial<Omit<HirerUser, 'createdAt' | 'email'>>): Promise<HirerUser> =>
    axios.post(`${hirerUrl}/hirer/${hirerId}/user/${userId}`, payload, {
      requestKey: `hirer/user/update/${userId}`,
      isInterruptive: true,
      withCredentials: true,
      feedback: {
        loading: 'Updating...',
        failure: true,
      },
    });

  return {
    claimProfile,
    createCompany,
    createUser,
    emailExists,
    getHirer,
    getUser,
    listHirerUsers,
    updateHirer,
    updateUser,
  };
};
