import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Activity, HirerUser, Settings, Talent } from '../helper/type';

type AuthUser = {
  id: string;
  username: string;
  name: string;
  email: string;
  remember: boolean;
  verified: boolean;
  exp: number;
  type: 'talent' | 'hirer';
};

type AuthHirer = Omit<HirerUser, 'createdAt'> & { isImpersonator?: boolean };

type Impersonator = {
  impersonateId: string;
  impersonateKey: string;
};

type Activities = {
  pending: Array<Activity>;
  scheduled: Array<Activity>;
  other: Array<Activity>;
};

interface UserState {
  authUser: AuthUser;
  authHirer: AuthHirer;
  talent: Partial<Talent>;
  isTalentOnboarded: boolean;
  profilePictureUrl: string;
  impersonator: Impersonator;
  settings: Partial<Settings>;
  lastVerifyEmailResendTime: string;
  lastForgotPassEmailResendTime: string;
  isPWA: boolean;
  activities: Activities;
}

const initialState = {
  authUser: {},
  authHirer: {},
  talent: {},
  isTalentOnboarded: false,
  profilePictureUrl: '',
  impersonator: {},
  settings: {},
  lastVerifyEmailResendTime: '',
  lastForgotPassEmailResendTime: '',
  activities: {
    pending: new Array<Activity>(),
    scheduled: new Array<Activity>(),
    other: new Array<Activity>(),
  },
} as UserState;

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<AuthUser>) => {
      const { remember } = action.payload;
      if (remember) {
        localStorage.setItem('_user', JSON.stringify(action.payload));
      }
      state.authUser = action.payload;
    },
    clearAuthUser: (state) => {
      localStorage.removeItem('_user');
      state.authUser = initialState.authUser;
    },
    setPartialTalentProfile: (
      state,
      action: PayloadAction<Partial<Talent>>
    ) => {
      state.talent = {
        ...state.talent,
        ...action.payload,
      };
      localStorage.setItem('_talent', JSON.stringify(state.talent));
    },
    clearTalentProfile: (state) => {
      state.talent = initialState.talent;
      localStorage.removeItem('_talent');
    },
    setProfilePicture: (
      state,
      action: PayloadAction<{
        profilePictureUrl: string;
      }>
    ) => {
      state.profilePictureUrl = action.payload.profilePictureUrl;
      localStorage.setItem(
        '_profilePictureUrl',
        JSON.stringify(action.payload)
      );
    },
    clearProfilePicture: (state) => {
      state.profilePictureUrl = initialState.profilePictureUrl;
      localStorage.removeItem('_profilePictureUrl');
    },
    setIsTalentOnboarded: (
      state,
      action: PayloadAction<{ isOnboarded: boolean }>
    ) => {
      state.isTalentOnboarded = action.payload.isOnboarded;
      localStorage.setItem(
        '_isTalentOnboarded',
        JSON.stringify(action.payload)
      );
    },
    clearIsTalentOnboarded: (state) => {
      state.isTalentOnboarded = initialState.isTalentOnboarded;
      localStorage.removeItem('_isTalentOnboarded');
    },
    setImpersonator: (state, action: PayloadAction<Impersonator>) => {
      state.impersonator = action.payload;
    },
    clearImpersonator: (state) => {
      state.impersonator = initialState.impersonator;
    },
    setPartialSettings: (state, action: PayloadAction<Partial<Settings>>) => {
      state.settings = {
        ...state.settings,
        ...action.payload,
      };
    },
    clearSettings: (state) => {
      state.settings = initialState.settings;
    },
    setLastVerifyEmailResendTime: (
      state,
      action: PayloadAction<{ lastVerifyEmailResendTime: string }>
    ) => {
      state.lastVerifyEmailResendTime =
        action.payload.lastVerifyEmailResendTime;
      localStorage.setItem(
        '_lastVerifyEmailResendTime',
        JSON.stringify(action.payload)
      );
    },
    clearLastVerifyEmailResendTime: (state) => {
      state.lastVerifyEmailResendTime = initialState.lastVerifyEmailResendTime;
      localStorage.removeItem('_lastVerifyEmailResendTime');
    },
    setLastForgotPassEmailResendTime: (
      state,
      action: PayloadAction<{ lastForgotPassEmailResendTime: string }>
    ) => {
      state.lastForgotPassEmailResendTime =
        action.payload.lastForgotPassEmailResendTime;
      localStorage.setItem(
        '_lastForgotPassEmailResendTime',
        JSON.stringify(action.payload)
      );
    },
    clearLastForgotPassEmailResendTime: (state) => {
      state.lastForgotPassEmailResendTime =
        initialState.lastForgotPassEmailResendTime;
      localStorage.removeItem('_lastForgotPassEmailResendTime');
    },
    setActivities: (state, action: PayloadAction<Partial<Activities>>) => {
      state.activities = {
        ...state.activities,
        ...action.payload,
      };
    },
    clearActivities: (state) => {
      state.activities = initialState.activities;
    },
    setAuthHirer: (state, action: PayloadAction<AuthHirer>) => {
      localStorage.setItem('_authHirer', JSON.stringify(action.payload));
      state.authHirer = action.payload;
    },
    clearAuthHirer: (state) => {
      localStorage.removeItem('_authHirer');
      state.authHirer = initialState.authHirer;
    },
  },
});

export const {
  setAuthUser,
  clearAuthUser,
  setPartialTalentProfile,
  clearTalentProfile,
  setIsTalentOnboarded,
  clearIsTalentOnboarded,
  setProfilePicture,
  clearProfilePicture,
  setImpersonator,
  clearImpersonator,
  setPartialSettings,
  clearSettings,
  setLastVerifyEmailResendTime,
  clearLastVerifyEmailResendTime,
  setLastForgotPassEmailResendTime,
  clearLastForgotPassEmailResendTime,
  setActivities,
  clearActivities,
  setAuthHirer,
  clearAuthHirer,
} = authSlice.actions;

export default authSlice.reducer;
